import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/wilmer/projects/wilmercantillo.com/src/components/md/index.tsx";
import { graphql } from 'gatsby';
import { Badge, Link, Text } from '@chakra-ui/layout';
import { FaExternalLinkAlt } from 'react-icons/fa';
export const ProjectQuery = graphql`
  {
    site {
      siteMetadata {
        description
        title
      }
    }
    banner: file(
      relativePath: { eq: "project/orange-delivery/images/banner.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  ProjectQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <p>{`Orange was a project that I carried out with 3 colleagues at the beginning of
2020 as an initiative to innovate in the field of logistics and supply chain.
However, due to the pandemic I was unable to continue working on it as I saw the
need to look for a job that would allow me and my family to have a greater
economic stability. Despite this during the construction of this project I
gained a lot of new knowledge and skills.`}</p>
    <p>{`Personally I was in charge of both the design and the construction of the app.
My colleagues were in charge of marketing the service through social networks
and delivery guys recruitment.`}</p>
    <h1>{`Goal`}</h1>
    <p>{`The objective of this project was to build the company's landing page and a web
app that would allow the management of packages transport, that is, an optimized
service for the last mile.`}</p>
    <p>{`The application consists of an interface, with authentication functionality,
that allows the end user and SMEs request shipments, as well as a bot that
receives the request and sends it to the telegram group, in which a delivery guy
can accept and receive details of that request. In addition, the interface of
users allows them to observe the location in real time of the delivery guy,
estimated delivery time and notifies when the delivery guy reaches the
destination.`}</p>
    <h1>{`Project construction`}</h1>
    <p>{`During this period I was very interested in learning about how to improve the
user experience so I take an online UX / UI course where I learned about the
process of designing a user interface. Based on these principles and after
looking at multiple designs for inspiration I started doing the wireframes and
then I was iterating based on the feedback of my colleagues.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/9b6e34830943348fca2777bfc53b9b89/24f41/wireframes.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "107%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAYAAABG1c6oAAAACXBIWXMAAAsTAAALEwEAmpwYAAAD50lEQVQ4y1WUTW9bRRiFp3QDC6QWdggTNjV7VqyowN2hIH4Bqy5QF72oBVb8h6qLqkIiiUIlfgFCTa2SLBxHqRLhOJt8ubZjO7ZjO/624+vrOzxn4pjE0vHMnXnnvOf9mDGFQsEUi0VzfHxs6vW6qVQqplwum0aj4b77/b75Y3nZ5LJZU8S2Wq2aBuu101PTajaN7/vXYE7ZAN9hmOz1en+12+2XrVbrZbfb/bvb6fwzGAye34vF5OQDnP4G0RJ7C+1Wa6Hf6y2MRqNFYTwefwaMgcB0Op1fdnd37fr6ut3c3LQcthyy7U7HYvT26ZMn5m0mM3dSKtnyyYmtsF8qFu3Z2ZkNgkA2FtKYUwiZwnoYj8ftzs7OkO8xoY45OKpWKvb8/PzfH+7fN5mjowhEHUgDyM7LpdKoXquNms3mCOc+hF8C43IGPGAJ3cdrSP6kIJCa4XCYfnBB+Im+L1VqlEIcykb4ivQYU6AYwCPh9jif90l4qHAhD1BrwzBM//nihTk6PPwIJweQFVCa40x+OBjkkslkfnt7O2+t/QIYVy3gQWQJwYckFBEqA4qEjU2BmzL+fXHxhrnyi9y+PZtr3xFOJ569+PkgVAgkOECdFO7oAF1wK5/LPa3Vas9x9AynDuT/GV2h+R0VeEbIh6UYPlULOWTJaUDC7WQySS8vLSnkOVX2tFq1ioCUWNlprrMgpgIbJRJ4QIn18RaWSDiKAiUdBenX8bjJ5/ORUqHQY105HkPowDyATPm+6wj1R795qqwUoixUBVEXaA0H6devXpnDg4MICofkWz06YT9UR3SV8wuFd13I5EPwFAIHfEZHSGgBlbQYp3XtOByBbCD1OAnAhDVFMYFoMlMoVinU7QA+CE/oM1VZTthP60oR+pxyJULlDkLXh4LWIfvaEdKYgkeXq0n9Xr8fqllBMF1LUfF3OHhzHAR3KGAUgighR3EcZT2KsygO3nOEVNGgwLVNJpPxV1ZWwtXVVbu2thaIWG2D2huofTebzX6Dum8p4LyAw3ls5iGbJ20f/h9yu+3pduDNXb1EImFzuVyAZxfy1ps3er4+VU6psp22mMO0ZYR7riiwC970VvgoCnXo8uqJMJ1KGZpaVVbbTOqNho/qMWrVZmM9KKqyU6gHVFVWi2xtbfn7+/uhKq0KuicMwmQi4R4H+nCoQqlX2XO4UpRrhA+lamNjY7i3t6cbIsi7DFOoMzwIer766gIpUutg48ZrjY0X4WcpFKlC1/wSGGV0PSnCnBzwartrp2t5NY/YxWY3BXwO6a9s/IjhIw4+0qiXHHxPWMrz+8w9IvqJcWbDmcd8P2b8WEX5D3K5ce0k1lFQAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Wireframes",
            "title": "Wireframes",
            "src": "/static/9b6e34830943348fca2777bfc53b9b89/c1b63/wireframes.png",
            "srcSet": ["/static/9b6e34830943348fca2777bfc53b9b89/5a46d/wireframes.png 300w", "/static/9b6e34830943348fca2777bfc53b9b89/0a47e/wireframes.png 600w", "/static/9b6e34830943348fca2777bfc53b9b89/c1b63/wireframes.png 1200w", "/static/9b6e34830943348fca2777bfc53b9b89/d61c2/wireframes.png 1800w", "/static/9b6e34830943348fca2777bfc53b9b89/97a96/wireframes.png 2400w", "/static/9b6e34830943348fca2777bfc53b9b89/24f41/wireframes.png 3947w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`The goal of wireframes is to build the UI layout in black and white, play with
the contrast in the content so that the sections more important highlights more.
After being satisfied with the result of this section the fonts, colors and
images were added. I did most of the icons and some of the illustrations which I
designed using adobe illustrator. This was the result of the design:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/9b418b34b134cb51140f0f4c99e1bba0/9e8c2/design.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "89.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEfklEQVQ4y12U248URRTGi51h4l+A4UVNjI8QE33yAeHBxBg18UGNJhrQGI1GEljDxmuikCBRwHBx4yVCQBdRgRWE5SZZdZdl2IVlr87C7GV6d6enu6en69Jdfaua4+lRVmMlX+qkuvurU/U7pwkBILfHc6it5L/jGnny6RRfgZY69+1rrWK8jByAtgdPQRsARUHb+QHedsNAL2ftmuXulldOOx+8PsJ2bL4a7e24yo7uGZ+emPzKDxqdgrnjfhBcDWQ4GEbxKKi4+9gt2H5pNpqrWfZIzXbGLMedrNet68xz7iOlR0nB2dXuiG8+gfiHPSB7voRw+CRAyIph6P0uhAcNyoAxDpwL0HFoHL0J310zGHC3BrZtQ61WBcc2NXWrq0jnoS2FoV0bK40DH0D488fpmS82Jl2/7Ib5xnxvIIJ+j3poxIGioWNbIAVd7C7D0TPTKdQdGxpOFdxMtomztZqMAhTGPn3Z+LXjWSjfuKiLl75XE8UeYDr+g7NgR8NjvWjW4/v+2VJp6lJlbu6gJZJNU7XgvGXbxxv2QrdTWzjpWLWf6nX3bkIHugrnO9qNnvUbwB4c1l4EijEJqgl9VgmI05AtIDu/vrgE5/73LpCt14GYtiBudYo0oxrpGxgilVpEiO+rAkubBktSCFVTizBRUaog1s2+loEeJ48/s54UBwfI6a5u8vaLG5aM32rfSOYWHLK/88DSGrlyZaDgMW543IcgjDQeTQnhQ6L0ZU6t/b5o9PqBPBeG0bkkjn6LQ/k53mm753n9+G6PEOIcY+zCbGXxtO007iHDc3ZBhpGB7uCjIfcDHcUJRKm+HMn6hOB/U6bMh8pEGXh1fkIDfEsphUqlAoZhIOUaJEkMWuvVWZqFqgRjtK6By1jTMNWGAIhV8zJjdJQyLBmkzDFr23IAsxpNkuSQUgpc14VqtQpRFKFhks2rWoaLPDYmbQk8CLUXKm0gmRgzpFwcblA+goZF3xfFCAsbj34YP34/juNJnAdQRYyHUP2oe0mMhjpNDR2GIMNQR4FQ0GxCqlQGJUeFk4skzYO2cnUXclmbZa2XPXPrVk7VJ3OC82w914KSZZgk2qA2Bc6YnpkaVzFSRgBoaBKtOZmenSWWZS2RvK1miiXF+olKk38p4wUXAiEMySi4zNcNLpXvByDjtJ951jakfCyQ8giC61JandA63Y5UH0OdiKOwS1LzCG7eNb9oHS/dqjzUyhAcswIzYxno9B9loy8K6uO3ezmQAbg3Z8BbqJc06C1JqiENKTRHPoM0lpCkKZ4qfoE89epHhTff3Wtt+rAT3nhnD2AMr3XszuJBxukQZtLq4yAQYM5WwVykIwDNzUgby8WE2nwZTNMEKbG7lHqekDseWE5WrOkv3PXIPLlzbZmsXFdetnJdlax4+EfKxAmP8kU0LGMBlxOVmmkan0ri5CUsETMIgluBjKazGQlXkfgTrYscvP5nftvOg/nhyZl8uWLmz/YO5sdKczn8D+Zc7ufTROYBvLw9I/N2tZmLE5aRzv9fuOmyvwBJzHZtP4uxwgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Design",
            "title": "Design",
            "src": "/static/9b418b34b134cb51140f0f4c99e1bba0/c1b63/design.png",
            "srcSet": ["/static/9b418b34b134cb51140f0f4c99e1bba0/5a46d/design.png 300w", "/static/9b418b34b134cb51140f0f4c99e1bba0/0a47e/design.png 600w", "/static/9b418b34b134cb51140f0f4c99e1bba0/c1b63/design.png 1200w", "/static/9b418b34b134cb51140f0f4c99e1bba0/d61c2/design.png 1800w", "/static/9b418b34b134cb51140f0f4c99e1bba0/97a96/design.png 2400w", "/static/9b418b34b134cb51140f0f4c99e1bba0/9e8c2/design.png 5047w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <Link target="_ blank" href="https://www.figma.com/file/t8t5Ik3f0PMjvITNDDoZbs/orange-web-app?node-id=212667%3A914" mdxType="Link">
  <Badge variant="subtle" colorScheme="black" textTransform="capitalize" float="right" display="flex" alignItems="center" mdxType="Badge">
    <Text marginRight="0.4rem" mdxType="Text">Design</Text>
    <FaExternalLinkAlt mdxType="FaExternalLinkAlt" />
  </Badge>
    </Link>
    <p>{`Then I started to do the development, using the libraries React Js and Redux.
When making the design, I also used the documentation of the design system
`}<em parentName="p">{`material`}</em>{` by google so I also used material ui library for frontend. Also used
the react hook form library for handling forms and build my own components for
the functionalities of the map with google maps api. To manage the status of the
request, I used the database (mysql) and a real-time connection to the backend
with the library socket io.`}</p>
    <p>{`The backend is a typical expressjs application with the MVC structure, using
express-session and passport to handle authentication by sessions. The bot in
telegram has the functionalities of registration of devery guys and change of
the status of a shipping, it also has a connection in real time with the user
interface via socket.`}</p>
    <p>{`I deployed the application to an AWS EC2 instance, which serves the application
inside a reverse proxy and the database on AWS RDS.`}</p>
    <h1>{`Conclution`}</h1>
    <p>{`Carrying out this project was a very challenging and enriching experience since
I was charge of the complete development cycle of a web application.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      